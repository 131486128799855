/**
 * Do not edit directly
 * Generated on Mon Jun 03 2024 05:15:09 GMT+0000 (Coordinated Universal Time)
 */

.Color-icon-gray-50 path,
.Color-icon-gray-50 rect,
.Color-icon-gray-50 polygon,
.Color-icon-gray-50 circle,
.Color-icon-gray-50 {
	fill: var(--color-gray-50);
}

.Color-icon-gray-100 path,
.Color-icon-gray-100 rect,
.Color-icon-gray-100 polygon,
.Color-icon-gray-100 circle,
.Color-icon-gray-100 {
	fill: var(--color-gray-100);
}

.Color-icon-gray-200 path,
.Color-icon-gray-200 rect,
.Color-icon-gray-200 polygon,
.Color-icon-gray-200 circle,
.Color-icon-gray-200 {
	fill: var(--color-gray-200);
}

.Color-icon-gray-300 path,
.Color-icon-gray-300 rect,
.Color-icon-gray-300 polygon,
.Color-icon-gray-300 circle,
.Color-icon-gray-300 {
	fill: var(--color-gray-300);
}

.Color-icon-gray-400 path,
.Color-icon-gray-400 rect,
.Color-icon-gray-400 polygon,
.Color-icon-gray-400 circle,
.Color-icon-gray-400 {
	fill: var(--color-gray-400);
}

.Color-icon-gray-500 path,
.Color-icon-gray-500 rect,
.Color-icon-gray-500 polygon,
.Color-icon-gray-500 circle,
.Color-icon-gray-500 {
	fill: var(--color-gray-500);
}

.Color-icon-gray-600 path,
.Color-icon-gray-600 rect,
.Color-icon-gray-600 polygon,
.Color-icon-gray-600 circle,
.Color-icon-gray-600 {
	fill: var(--color-gray-600);
}

.Color-icon-gray-700 path,
.Color-icon-gray-700 rect,
.Color-icon-gray-700 polygon,
.Color-icon-gray-700 circle,
.Color-icon-gray-700 {
	fill: var(--color-gray-700);
}

.Color-icon-gray-800 path,
.Color-icon-gray-800 rect,
.Color-icon-gray-800 polygon,
.Color-icon-gray-800 circle,
.Color-icon-gray-800 {
	fill: var(--color-gray-800);
}

.Color-icon-gray-900 path,
.Color-icon-gray-900 rect,
.Color-icon-gray-900 polygon,
.Color-icon-gray-900 circle,
.Color-icon-gray-900 {
	fill: var(--color-gray-900);
}

.Color-icon-gray-1000 path,
.Color-icon-gray-1000 rect,
.Color-icon-gray-1000 polygon,
.Color-icon-gray-1000 circle,
.Color-icon-gray-1000 {
	fill: var(--color-gray-1000);
}

.Color-icon-gray-1100 path,
.Color-icon-gray-1100 rect,
.Color-icon-gray-1100 polygon,
.Color-icon-gray-1100 circle,
.Color-icon-gray-1100 {
	fill: var(--color-gray-1100);
}

.Color-icon-gray-000 path,
.Color-icon-gray-000 rect,
.Color-icon-gray-000 polygon,
.Color-icon-gray-000 circle,
.Color-icon-gray-000 {
	fill: var(--color-gray-000);
}

.Color-icon-neutral-50 path,
.Color-icon-neutral-50 rect,
.Color-icon-neutral-50 polygon,
.Color-icon-neutral-50 circle,
.Color-icon-neutral-50 {
	fill: var(--color-neutral-50);
}

.Color-icon-neutral-100 path,
.Color-icon-neutral-100 rect,
.Color-icon-neutral-100 polygon,
.Color-icon-neutral-100 circle,
.Color-icon-neutral-100 {
	fill: var(--color-neutral-100);
}

.Color-icon-neutral-200 path,
.Color-icon-neutral-200 rect,
.Color-icon-neutral-200 polygon,
.Color-icon-neutral-200 circle,
.Color-icon-neutral-200 {
	fill: var(--color-neutral-200);
}

.Color-icon-neutral-300 path,
.Color-icon-neutral-300 rect,
.Color-icon-neutral-300 polygon,
.Color-icon-neutral-300 circle,
.Color-icon-neutral-300 {
	fill: var(--color-neutral-300);
}

.Color-icon-neutral-400 path,
.Color-icon-neutral-400 rect,
.Color-icon-neutral-400 polygon,
.Color-icon-neutral-400 circle,
.Color-icon-neutral-400 {
	fill: var(--color-neutral-400);
}

.Color-icon-neutral-500 path,
.Color-icon-neutral-500 rect,
.Color-icon-neutral-500 polygon,
.Color-icon-neutral-500 circle,
.Color-icon-neutral-500 {
	fill: var(--color-neutral-500);
}

.Color-icon-neutral-600 path,
.Color-icon-neutral-600 rect,
.Color-icon-neutral-600 polygon,
.Color-icon-neutral-600 circle,
.Color-icon-neutral-600 {
	fill: var(--color-neutral-600);
}

.Color-icon-neutral-700 path,
.Color-icon-neutral-700 rect,
.Color-icon-neutral-700 polygon,
.Color-icon-neutral-700 circle,
.Color-icon-neutral-700 {
	fill: var(--color-neutral-700);
}

.Color-icon-neutral-800 path,
.Color-icon-neutral-800 rect,
.Color-icon-neutral-800 polygon,
.Color-icon-neutral-800 circle,
.Color-icon-neutral-800 {
	fill: var(--color-neutral-800);
}

.Color-icon-neutral-900 path,
.Color-icon-neutral-900 rect,
.Color-icon-neutral-900 polygon,
.Color-icon-neutral-900 circle,
.Color-icon-neutral-900 {
	fill: var(--color-neutral-900);
}

.Color-icon-neutral-1000 path,
.Color-icon-neutral-1000 rect,
.Color-icon-neutral-1000 polygon,
.Color-icon-neutral-1000 circle,
.Color-icon-neutral-1000 {
	fill: var(--color-neutral-1000);
}

.Color-icon-neutral-1100 path,
.Color-icon-neutral-1100 rect,
.Color-icon-neutral-1100 polygon,
.Color-icon-neutral-1100 circle,
.Color-icon-neutral-1100 {
	fill: var(--color-neutral-1100);
}

.Color-icon-neutral-000 path,
.Color-icon-neutral-000 rect,
.Color-icon-neutral-000 polygon,
.Color-icon-neutral-000 circle,
.Color-icon-neutral-000 {
	fill: var(--color-neutral-000);
}

.Color-icon-indigo-50 path,
.Color-icon-indigo-50 rect,
.Color-icon-indigo-50 polygon,
.Color-icon-indigo-50 circle,
.Color-icon-indigo-50 {
	fill: var(--color-indigo-50);
}

.Color-icon-indigo-100 path,
.Color-icon-indigo-100 rect,
.Color-icon-indigo-100 polygon,
.Color-icon-indigo-100 circle,
.Color-icon-indigo-100 {
	fill: var(--color-indigo-100);
}

.Color-icon-indigo-200 path,
.Color-icon-indigo-200 rect,
.Color-icon-indigo-200 polygon,
.Color-icon-indigo-200 circle,
.Color-icon-indigo-200 {
	fill: var(--color-indigo-200);
}

.Color-icon-indigo-300 path,
.Color-icon-indigo-300 rect,
.Color-icon-indigo-300 polygon,
.Color-icon-indigo-300 circle,
.Color-icon-indigo-300 {
	fill: var(--color-indigo-300);
}

.Color-icon-indigo-400 path,
.Color-icon-indigo-400 rect,
.Color-icon-indigo-400 polygon,
.Color-icon-indigo-400 circle,
.Color-icon-indigo-400 {
	fill: var(--color-indigo-400);
}

.Color-icon-indigo-500 path,
.Color-icon-indigo-500 rect,
.Color-icon-indigo-500 polygon,
.Color-icon-indigo-500 circle,
.Color-icon-indigo-500 {
	fill: var(--color-indigo-500);
}

.Color-icon-indigo-600 path,
.Color-icon-indigo-600 rect,
.Color-icon-indigo-600 polygon,
.Color-icon-indigo-600 circle,
.Color-icon-indigo-600 {
	fill: var(--color-indigo-600);
}

.Color-icon-indigo-700 path,
.Color-icon-indigo-700 rect,
.Color-icon-indigo-700 polygon,
.Color-icon-indigo-700 circle,
.Color-icon-indigo-700 {
	fill: var(--color-indigo-700);
}

.Color-icon-indigo-800 path,
.Color-icon-indigo-800 rect,
.Color-icon-indigo-800 polygon,
.Color-icon-indigo-800 circle,
.Color-icon-indigo-800 {
	fill: var(--color-indigo-800);
}

.Color-icon-indigo-900 path,
.Color-icon-indigo-900 rect,
.Color-icon-indigo-900 polygon,
.Color-icon-indigo-900 circle,
.Color-icon-indigo-900 {
	fill: var(--color-indigo-900);
}

.Color-icon-indigo-1000 path,
.Color-icon-indigo-1000 rect,
.Color-icon-indigo-1000 polygon,
.Color-icon-indigo-1000 circle,
.Color-icon-indigo-1000 {
	fill: var(--color-indigo-1000);
}

.Color-icon-indigo-1100 path,
.Color-icon-indigo-1100 rect,
.Color-icon-indigo-1100 polygon,
.Color-icon-indigo-1100 circle,
.Color-icon-indigo-1100 {
	fill: var(--color-indigo-1100);
}

.Color-icon-navy-50 path,
.Color-icon-navy-50 rect,
.Color-icon-navy-50 polygon,
.Color-icon-navy-50 circle,
.Color-icon-navy-50 {
	fill: var(--color-navy-50);
}

.Color-icon-navy-100 path,
.Color-icon-navy-100 rect,
.Color-icon-navy-100 polygon,
.Color-icon-navy-100 circle,
.Color-icon-navy-100 {
	fill: var(--color-navy-100);
}

.Color-icon-navy-200 path,
.Color-icon-navy-200 rect,
.Color-icon-navy-200 polygon,
.Color-icon-navy-200 circle,
.Color-icon-navy-200 {
	fill: var(--color-navy-200);
}

.Color-icon-navy-300 path,
.Color-icon-navy-300 rect,
.Color-icon-navy-300 polygon,
.Color-icon-navy-300 circle,
.Color-icon-navy-300 {
	fill: var(--color-navy-300);
}

.Color-icon-navy-400 path,
.Color-icon-navy-400 rect,
.Color-icon-navy-400 polygon,
.Color-icon-navy-400 circle,
.Color-icon-navy-400 {
	fill: var(--color-navy-400);
}

.Color-icon-navy-500 path,
.Color-icon-navy-500 rect,
.Color-icon-navy-500 polygon,
.Color-icon-navy-500 circle,
.Color-icon-navy-500 {
	fill: var(--color-navy-500);
}

.Color-icon-navy-600 path,
.Color-icon-navy-600 rect,
.Color-icon-navy-600 polygon,
.Color-icon-navy-600 circle,
.Color-icon-navy-600 {
	fill: var(--color-navy-600);
}

.Color-icon-navy-700 path,
.Color-icon-navy-700 rect,
.Color-icon-navy-700 polygon,
.Color-icon-navy-700 circle,
.Color-icon-navy-700 {
	fill: var(--color-navy-700);
}

.Color-icon-navy-800 path,
.Color-icon-navy-800 rect,
.Color-icon-navy-800 polygon,
.Color-icon-navy-800 circle,
.Color-icon-navy-800 {
	fill: var(--color-navy-800);
}

.Color-icon-navy-900 path,
.Color-icon-navy-900 rect,
.Color-icon-navy-900 polygon,
.Color-icon-navy-900 circle,
.Color-icon-navy-900 {
	fill: var(--color-navy-900);
}

.Color-icon-navy-1000 path,
.Color-icon-navy-1000 rect,
.Color-icon-navy-1000 polygon,
.Color-icon-navy-1000 circle,
.Color-icon-navy-1000 {
	fill: var(--color-navy-1000);
}

.Color-icon-navy-1100 path,
.Color-icon-navy-1100 rect,
.Color-icon-navy-1100 polygon,
.Color-icon-navy-1100 circle,
.Color-icon-navy-1100 {
	fill: var(--color-navy-1100);
}

.Color-icon-critical-50 path,
.Color-icon-critical-50 rect,
.Color-icon-critical-50 polygon,
.Color-icon-critical-50 circle,
.Color-icon-critical-50 {
	fill: var(--color-critical-50);
}

.Color-icon-critical-100 path,
.Color-icon-critical-100 rect,
.Color-icon-critical-100 polygon,
.Color-icon-critical-100 circle,
.Color-icon-critical-100 {
	fill: var(--color-critical-100);
}

.Color-icon-critical-200 path,
.Color-icon-critical-200 rect,
.Color-icon-critical-200 polygon,
.Color-icon-critical-200 circle,
.Color-icon-critical-200 {
	fill: var(--color-critical-200);
}

.Color-icon-critical-300 path,
.Color-icon-critical-300 rect,
.Color-icon-critical-300 polygon,
.Color-icon-critical-300 circle,
.Color-icon-critical-300 {
	fill: var(--color-critical-300);
}

.Color-icon-critical-400 path,
.Color-icon-critical-400 rect,
.Color-icon-critical-400 polygon,
.Color-icon-critical-400 circle,
.Color-icon-critical-400 {
	fill: var(--color-critical-400);
}

.Color-icon-critical-500 path,
.Color-icon-critical-500 rect,
.Color-icon-critical-500 polygon,
.Color-icon-critical-500 circle,
.Color-icon-critical-500 {
	fill: var(--color-critical-500);
}

.Color-icon-critical-600 path,
.Color-icon-critical-600 rect,
.Color-icon-critical-600 polygon,
.Color-icon-critical-600 circle,
.Color-icon-critical-600 {
	fill: var(--color-critical-600);
}

.Color-icon-critical-700 path,
.Color-icon-critical-700 rect,
.Color-icon-critical-700 polygon,
.Color-icon-critical-700 circle,
.Color-icon-critical-700 {
	fill: var(--color-critical-700);
}

.Color-icon-critical-800 path,
.Color-icon-critical-800 rect,
.Color-icon-critical-800 polygon,
.Color-icon-critical-800 circle,
.Color-icon-critical-800 {
	fill: var(--color-critical-800);
}

.Color-icon-critical-900 path,
.Color-icon-critical-900 rect,
.Color-icon-critical-900 polygon,
.Color-icon-critical-900 circle,
.Color-icon-critical-900 {
	fill: var(--color-critical-900);
}

.Color-icon-critical-1000 path,
.Color-icon-critical-1000 rect,
.Color-icon-critical-1000 polygon,
.Color-icon-critical-1000 circle,
.Color-icon-critical-1000 {
	fill: var(--color-critical-1000);
}

.Color-icon-critical-1100 path,
.Color-icon-critical-1100 rect,
.Color-icon-critical-1100 polygon,
.Color-icon-critical-1100 circle,
.Color-icon-critical-1100 {
	fill: var(--color-critical-1100);
}

.Color-icon-success-50 path,
.Color-icon-success-50 rect,
.Color-icon-success-50 polygon,
.Color-icon-success-50 circle,
.Color-icon-success-50 {
	fill: var(--color-success-50);
}

.Color-icon-success-100 path,
.Color-icon-success-100 rect,
.Color-icon-success-100 polygon,
.Color-icon-success-100 circle,
.Color-icon-success-100 {
	fill: var(--color-success-100);
}

.Color-icon-success-200 path,
.Color-icon-success-200 rect,
.Color-icon-success-200 polygon,
.Color-icon-success-200 circle,
.Color-icon-success-200 {
	fill: var(--color-success-200);
}

.Color-icon-success-300 path,
.Color-icon-success-300 rect,
.Color-icon-success-300 polygon,
.Color-icon-success-300 circle,
.Color-icon-success-300 {
	fill: var(--color-success-300);
}

.Color-icon-success-400 path,
.Color-icon-success-400 rect,
.Color-icon-success-400 polygon,
.Color-icon-success-400 circle,
.Color-icon-success-400 {
	fill: var(--color-success-400);
}

.Color-icon-success-500 path,
.Color-icon-success-500 rect,
.Color-icon-success-500 polygon,
.Color-icon-success-500 circle,
.Color-icon-success-500 {
	fill: var(--color-success-500);
}

.Color-icon-success-600 path,
.Color-icon-success-600 rect,
.Color-icon-success-600 polygon,
.Color-icon-success-600 circle,
.Color-icon-success-600 {
	fill: var(--color-success-600);
}

.Color-icon-success-700 path,
.Color-icon-success-700 rect,
.Color-icon-success-700 polygon,
.Color-icon-success-700 circle,
.Color-icon-success-700 {
	fill: var(--color-success-700);
}

.Color-icon-success-800 path,
.Color-icon-success-800 rect,
.Color-icon-success-800 polygon,
.Color-icon-success-800 circle,
.Color-icon-success-800 {
	fill: var(--color-success-800);
}

.Color-icon-success-900 path,
.Color-icon-success-900 rect,
.Color-icon-success-900 polygon,
.Color-icon-success-900 circle,
.Color-icon-success-900 {
	fill: var(--color-success-900);
}

.Color-icon-success-1000 path,
.Color-icon-success-1000 rect,
.Color-icon-success-1000 polygon,
.Color-icon-success-1000 circle,
.Color-icon-success-1000 {
	fill: var(--color-success-1000);
}

.Color-icon-success-1100 path,
.Color-icon-success-1100 rect,
.Color-icon-success-1100 polygon,
.Color-icon-success-1100 circle,
.Color-icon-success-1100 {
	fill: var(--color-success-1100);
}

.Color-icon-warning-50 path,
.Color-icon-warning-50 rect,
.Color-icon-warning-50 polygon,
.Color-icon-warning-50 circle,
.Color-icon-warning-50 {
	fill: var(--color-warning-50);
}

.Color-icon-warning-100 path,
.Color-icon-warning-100 rect,
.Color-icon-warning-100 polygon,
.Color-icon-warning-100 circle,
.Color-icon-warning-100 {
	fill: var(--color-warning-100);
}

.Color-icon-warning-200 path,
.Color-icon-warning-200 rect,
.Color-icon-warning-200 polygon,
.Color-icon-warning-200 circle,
.Color-icon-warning-200 {
	fill: var(--color-warning-200);
}

.Color-icon-warning-300 path,
.Color-icon-warning-300 rect,
.Color-icon-warning-300 polygon,
.Color-icon-warning-300 circle,
.Color-icon-warning-300 {
	fill: var(--color-warning-300);
}

.Color-icon-warning-400 path,
.Color-icon-warning-400 rect,
.Color-icon-warning-400 polygon,
.Color-icon-warning-400 circle,
.Color-icon-warning-400 {
	fill: var(--color-warning-400);
}

.Color-icon-warning-500 path,
.Color-icon-warning-500 rect,
.Color-icon-warning-500 polygon,
.Color-icon-warning-500 circle,
.Color-icon-warning-500 {
	fill: var(--color-warning-500);
}

.Color-icon-warning-600 path,
.Color-icon-warning-600 rect,
.Color-icon-warning-600 polygon,
.Color-icon-warning-600 circle,
.Color-icon-warning-600 {
	fill: var(--color-warning-600);
}

.Color-icon-warning-700 path,
.Color-icon-warning-700 rect,
.Color-icon-warning-700 polygon,
.Color-icon-warning-700 circle,
.Color-icon-warning-700 {
	fill: var(--color-warning-700);
}

.Color-icon-warning-800 path,
.Color-icon-warning-800 rect,
.Color-icon-warning-800 polygon,
.Color-icon-warning-800 circle,
.Color-icon-warning-800 {
	fill: var(--color-warning-800);
}

.Color-icon-warning-900 path,
.Color-icon-warning-900 rect,
.Color-icon-warning-900 polygon,
.Color-icon-warning-900 circle,
.Color-icon-warning-900 {
	fill: var(--color-warning-900);
}

.Color-icon-warning-1000 path,
.Color-icon-warning-1000 rect,
.Color-icon-warning-1000 polygon,
.Color-icon-warning-1000 circle,
.Color-icon-warning-1000 {
	fill: var(--color-warning-1000);
}

.Color-icon-warning-1100 path,
.Color-icon-warning-1100 rect,
.Color-icon-warning-1100 polygon,
.Color-icon-warning-1100 circle,
.Color-icon-warning-1100 {
	fill: var(--color-warning-1100);
}

.Color-icon-info-50 path,
.Color-icon-info-50 rect,
.Color-icon-info-50 polygon,
.Color-icon-info-50 circle,
.Color-icon-info-50 {
	fill: var(--color-info-50);
}

.Color-icon-info-100 path,
.Color-icon-info-100 rect,
.Color-icon-info-100 polygon,
.Color-icon-info-100 circle,
.Color-icon-info-100 {
	fill: var(--color-info-100);
}

.Color-icon-info-200 path,
.Color-icon-info-200 rect,
.Color-icon-info-200 polygon,
.Color-icon-info-200 circle,
.Color-icon-info-200 {
	fill: var(--color-info-200);
}

.Color-icon-info-300 path,
.Color-icon-info-300 rect,
.Color-icon-info-300 polygon,
.Color-icon-info-300 circle,
.Color-icon-info-300 {
	fill: var(--color-info-300);
}

.Color-icon-info-400 path,
.Color-icon-info-400 rect,
.Color-icon-info-400 polygon,
.Color-icon-info-400 circle,
.Color-icon-info-400 {
	fill: var(--color-info-400);
}

.Color-icon-info-500 path,
.Color-icon-info-500 rect,
.Color-icon-info-500 polygon,
.Color-icon-info-500 circle,
.Color-icon-info-500 {
	fill: var(--color-info-500);
}

.Color-icon-info-600 path,
.Color-icon-info-600 rect,
.Color-icon-info-600 polygon,
.Color-icon-info-600 circle,
.Color-icon-info-600 {
	fill: var(--color-info-600);
}

.Color-icon-info-700 path,
.Color-icon-info-700 rect,
.Color-icon-info-700 polygon,
.Color-icon-info-700 circle,
.Color-icon-info-700 {
	fill: var(--color-info-700);
}

.Color-icon-info-800 path,
.Color-icon-info-800 rect,
.Color-icon-info-800 polygon,
.Color-icon-info-800 circle,
.Color-icon-info-800 {
	fill: var(--color-info-800);
}

.Color-icon-info-900 path,
.Color-icon-info-900 rect,
.Color-icon-info-900 polygon,
.Color-icon-info-900 circle,
.Color-icon-info-900 {
	fill: var(--color-info-900);
}

.Color-icon-info-1000 path,
.Color-icon-info-1000 rect,
.Color-icon-info-1000 polygon,
.Color-icon-info-1000 circle,
.Color-icon-info-1000 {
	fill: var(--color-info-1000);
}

.Color-icon-info-1100 path,
.Color-icon-info-1100 rect,
.Color-icon-info-1100 polygon,
.Color-icon-info-1100 circle,
.Color-icon-info-1100 {
	fill: var(--color-info-1100);
}

.Color-icon-teal-50 path,
.Color-icon-teal-50 rect,
.Color-icon-teal-50 polygon,
.Color-icon-teal-50 circle,
.Color-icon-teal-50 {
	fill: var(--color-teal-50);
}

.Color-icon-teal-100 path,
.Color-icon-teal-100 rect,
.Color-icon-teal-100 polygon,
.Color-icon-teal-100 circle,
.Color-icon-teal-100 {
	fill: var(--color-teal-100);
}

.Color-icon-teal-200 path,
.Color-icon-teal-200 rect,
.Color-icon-teal-200 polygon,
.Color-icon-teal-200 circle,
.Color-icon-teal-200 {
	fill: var(--color-teal-200);
}

.Color-icon-teal-300 path,
.Color-icon-teal-300 rect,
.Color-icon-teal-300 polygon,
.Color-icon-teal-300 circle,
.Color-icon-teal-300 {
	fill: var(--color-teal-300);
}

.Color-icon-teal-400 path,
.Color-icon-teal-400 rect,
.Color-icon-teal-400 polygon,
.Color-icon-teal-400 circle,
.Color-icon-teal-400 {
	fill: var(--color-teal-400);
}

.Color-icon-teal-500 path,
.Color-icon-teal-500 rect,
.Color-icon-teal-500 polygon,
.Color-icon-teal-500 circle,
.Color-icon-teal-500 {
	fill: var(--color-teal-500);
}

.Color-icon-teal-600 path,
.Color-icon-teal-600 rect,
.Color-icon-teal-600 polygon,
.Color-icon-teal-600 circle,
.Color-icon-teal-600 {
	fill: var(--color-teal-600);
}

.Color-icon-teal-700 path,
.Color-icon-teal-700 rect,
.Color-icon-teal-700 polygon,
.Color-icon-teal-700 circle,
.Color-icon-teal-700 {
	fill: var(--color-teal-700);
}

.Color-icon-teal-800 path,
.Color-icon-teal-800 rect,
.Color-icon-teal-800 polygon,
.Color-icon-teal-800 circle,
.Color-icon-teal-800 {
	fill: var(--color-teal-800);
}

.Color-icon-teal-900 path,
.Color-icon-teal-900 rect,
.Color-icon-teal-900 polygon,
.Color-icon-teal-900 circle,
.Color-icon-teal-900 {
	fill: var(--color-teal-900);
}

.Color-icon-teal-1000 path,
.Color-icon-teal-1000 rect,
.Color-icon-teal-1000 polygon,
.Color-icon-teal-1000 circle,
.Color-icon-teal-1000 {
	fill: var(--color-teal-1000);
}

.Color-icon-teal-1100 path,
.Color-icon-teal-1100 rect,
.Color-icon-teal-1100 polygon,
.Color-icon-teal-1100 circle,
.Color-icon-teal-1100 {
	fill: var(--color-teal-1100);
}

.Color-icon-pink-50 path,
.Color-icon-pink-50 rect,
.Color-icon-pink-50 polygon,
.Color-icon-pink-50 circle,
.Color-icon-pink-50 {
	fill: var(--color-pink-50);
}

.Color-icon-pink-100 path,
.Color-icon-pink-100 rect,
.Color-icon-pink-100 polygon,
.Color-icon-pink-100 circle,
.Color-icon-pink-100 {
	fill: var(--color-pink-100);
}

.Color-icon-pink-200 path,
.Color-icon-pink-200 rect,
.Color-icon-pink-200 polygon,
.Color-icon-pink-200 circle,
.Color-icon-pink-200 {
	fill: var(--color-pink-200);
}

.Color-icon-pink-300 path,
.Color-icon-pink-300 rect,
.Color-icon-pink-300 polygon,
.Color-icon-pink-300 circle,
.Color-icon-pink-300 {
	fill: var(--color-pink-300);
}

.Color-icon-pink-400 path,
.Color-icon-pink-400 rect,
.Color-icon-pink-400 polygon,
.Color-icon-pink-400 circle,
.Color-icon-pink-400 {
	fill: var(--color-pink-400);
}

.Color-icon-pink-500 path,
.Color-icon-pink-500 rect,
.Color-icon-pink-500 polygon,
.Color-icon-pink-500 circle,
.Color-icon-pink-500 {
	fill: var(--color-pink-500);
}

.Color-icon-pink-600 path,
.Color-icon-pink-600 rect,
.Color-icon-pink-600 polygon,
.Color-icon-pink-600 circle,
.Color-icon-pink-600 {
	fill: var(--color-pink-600);
}

.Color-icon-pink-700 path,
.Color-icon-pink-700 rect,
.Color-icon-pink-700 polygon,
.Color-icon-pink-700 circle,
.Color-icon-pink-700 {
	fill: var(--color-pink-700);
}

.Color-icon-pink-800 path,
.Color-icon-pink-800 rect,
.Color-icon-pink-800 polygon,
.Color-icon-pink-800 circle,
.Color-icon-pink-800 {
	fill: var(--color-pink-800);
}

.Color-icon-pink-900 path,
.Color-icon-pink-900 rect,
.Color-icon-pink-900 polygon,
.Color-icon-pink-900 circle,
.Color-icon-pink-900 {
	fill: var(--color-pink-900);
}

.Color-icon-pink-1000 path,
.Color-icon-pink-1000 rect,
.Color-icon-pink-1000 polygon,
.Color-icon-pink-1000 circle,
.Color-icon-pink-1000 {
	fill: var(--color-pink-1000);
}

.Color-icon-pink-1100 path,
.Color-icon-pink-1100 rect,
.Color-icon-pink-1100 polygon,
.Color-icon-pink-1100 circle,
.Color-icon-pink-1100 {
	fill: var(--color-pink-1100);
}

.Color-icon-orange-50 path,
.Color-icon-orange-50 rect,
.Color-icon-orange-50 polygon,
.Color-icon-orange-50 circle,
.Color-icon-orange-50 {
	fill: var(--color-orange-50);
}

.Color-icon-orange-100 path,
.Color-icon-orange-100 rect,
.Color-icon-orange-100 polygon,
.Color-icon-orange-100 circle,
.Color-icon-orange-100 {
	fill: var(--color-orange-100);
}

.Color-icon-orange-200 path,
.Color-icon-orange-200 rect,
.Color-icon-orange-200 polygon,
.Color-icon-orange-200 circle,
.Color-icon-orange-200 {
	fill: var(--color-orange-200);
}

.Color-icon-orange-300 path,
.Color-icon-orange-300 rect,
.Color-icon-orange-300 polygon,
.Color-icon-orange-300 circle,
.Color-icon-orange-300 {
	fill: var(--color-orange-300);
}

.Color-icon-orange-400 path,
.Color-icon-orange-400 rect,
.Color-icon-orange-400 polygon,
.Color-icon-orange-400 circle,
.Color-icon-orange-400 {
	fill: var(--color-orange-400);
}

.Color-icon-orange-500 path,
.Color-icon-orange-500 rect,
.Color-icon-orange-500 polygon,
.Color-icon-orange-500 circle,
.Color-icon-orange-500 {
	fill: var(--color-orange-500);
}

.Color-icon-orange-600 path,
.Color-icon-orange-600 rect,
.Color-icon-orange-600 polygon,
.Color-icon-orange-600 circle,
.Color-icon-orange-600 {
	fill: var(--color-orange-600);
}

.Color-icon-orange-700 path,
.Color-icon-orange-700 rect,
.Color-icon-orange-700 polygon,
.Color-icon-orange-700 circle,
.Color-icon-orange-700 {
	fill: var(--color-orange-700);
}

.Color-icon-orange-800 path,
.Color-icon-orange-800 rect,
.Color-icon-orange-800 polygon,
.Color-icon-orange-800 circle,
.Color-icon-orange-800 {
	fill: var(--color-orange-800);
}

.Color-icon-orange-900 path,
.Color-icon-orange-900 rect,
.Color-icon-orange-900 polygon,
.Color-icon-orange-900 circle,
.Color-icon-orange-900 {
	fill: var(--color-orange-900);
}

.Color-icon-orange-1000 path,
.Color-icon-orange-1000 rect,
.Color-icon-orange-1000 polygon,
.Color-icon-orange-1000 circle,
.Color-icon-orange-1000 {
	fill: var(--color-orange-1000);
}

.Color-icon-orange-1100 path,
.Color-icon-orange-1100 rect,
.Color-icon-orange-1100 polygon,
.Color-icon-orange-1100 circle,
.Color-icon-orange-1100 {
	fill: var(--color-orange-1100);
}

.Color-icon-purple-50 path,
.Color-icon-purple-50 rect,
.Color-icon-purple-50 polygon,
.Color-icon-purple-50 circle,
.Color-icon-purple-50 {
	fill: var(--color-purple-50);
}

.Color-icon-purple-100 path,
.Color-icon-purple-100 rect,
.Color-icon-purple-100 polygon,
.Color-icon-purple-100 circle,
.Color-icon-purple-100 {
	fill: var(--color-purple-100);
}

.Color-icon-purple-200 path,
.Color-icon-purple-200 rect,
.Color-icon-purple-200 polygon,
.Color-icon-purple-200 circle,
.Color-icon-purple-200 {
	fill: var(--color-purple-200);
}

.Color-icon-purple-300 path,
.Color-icon-purple-300 rect,
.Color-icon-purple-300 polygon,
.Color-icon-purple-300 circle,
.Color-icon-purple-300 {
	fill: var(--color-purple-300);
}

.Color-icon-purple-400 path,
.Color-icon-purple-400 rect,
.Color-icon-purple-400 polygon,
.Color-icon-purple-400 circle,
.Color-icon-purple-400 {
	fill: var(--color-purple-400);
}

.Color-icon-purple-500 path,
.Color-icon-purple-500 rect,
.Color-icon-purple-500 polygon,
.Color-icon-purple-500 circle,
.Color-icon-purple-500 {
	fill: var(--color-purple-500);
}

.Color-icon-purple-600 path,
.Color-icon-purple-600 rect,
.Color-icon-purple-600 polygon,
.Color-icon-purple-600 circle,
.Color-icon-purple-600 {
	fill: var(--color-purple-600);
}

.Color-icon-purple-700 path,
.Color-icon-purple-700 rect,
.Color-icon-purple-700 polygon,
.Color-icon-purple-700 circle,
.Color-icon-purple-700 {
	fill: var(--color-purple-700);
}

.Color-icon-purple-800 path,
.Color-icon-purple-800 rect,
.Color-icon-purple-800 polygon,
.Color-icon-purple-800 circle,
.Color-icon-purple-800 {
	fill: var(--color-purple-800);
}

.Color-icon-purple-900 path,
.Color-icon-purple-900 rect,
.Color-icon-purple-900 polygon,
.Color-icon-purple-900 circle,
.Color-icon-purple-900 {
	fill: var(--color-purple-900);
}

.Color-icon-purple-1000 path,
.Color-icon-purple-1000 rect,
.Color-icon-purple-1000 polygon,
.Color-icon-purple-1000 circle,
.Color-icon-purple-1000 {
	fill: var(--color-purple-1000);
}

.Color-icon-purple-1100 path,
.Color-icon-purple-1100 rect,
.Color-icon-purple-1100 polygon,
.Color-icon-purple-1100 circle,
.Color-icon-purple-1100 {
	fill: var(--color-purple-1100);
}

.Color-icon-green-50 path,
.Color-icon-green-50 rect,
.Color-icon-green-50 polygon,
.Color-icon-green-50 circle,
.Color-icon-green-50 {
	fill: var(--color-green-50);
}

.Color-icon-green-100 path,
.Color-icon-green-100 rect,
.Color-icon-green-100 polygon,
.Color-icon-green-100 circle,
.Color-icon-green-100 {
	fill: var(--color-green-100);
}

.Color-icon-green-200 path,
.Color-icon-green-200 rect,
.Color-icon-green-200 polygon,
.Color-icon-green-200 circle,
.Color-icon-green-200 {
	fill: var(--color-green-200);
}

.Color-icon-green-300 path,
.Color-icon-green-300 rect,
.Color-icon-green-300 polygon,
.Color-icon-green-300 circle,
.Color-icon-green-300 {
	fill: var(--color-green-300);
}

.Color-icon-green-400 path,
.Color-icon-green-400 rect,
.Color-icon-green-400 polygon,
.Color-icon-green-400 circle,
.Color-icon-green-400 {
	fill: var(--color-green-400);
}

.Color-icon-green-500 path,
.Color-icon-green-500 rect,
.Color-icon-green-500 polygon,
.Color-icon-green-500 circle,
.Color-icon-green-500 {
	fill: var(--color-green-500);
}

.Color-icon-green-600 path,
.Color-icon-green-600 rect,
.Color-icon-green-600 polygon,
.Color-icon-green-600 circle,
.Color-icon-green-600 {
	fill: var(--color-green-600);
}

.Color-icon-green-700 path,
.Color-icon-green-700 rect,
.Color-icon-green-700 polygon,
.Color-icon-green-700 circle,
.Color-icon-green-700 {
	fill: var(--color-green-700);
}

.Color-icon-green-800 path,
.Color-icon-green-800 rect,
.Color-icon-green-800 polygon,
.Color-icon-green-800 circle,
.Color-icon-green-800 {
	fill: var(--color-green-800);
}

.Color-icon-green-900 path,
.Color-icon-green-900 rect,
.Color-icon-green-900 polygon,
.Color-icon-green-900 circle,
.Color-icon-green-900 {
	fill: var(--color-green-900);
}

.Color-icon-green-1000 path,
.Color-icon-green-1000 rect,
.Color-icon-green-1000 polygon,
.Color-icon-green-1000 circle,
.Color-icon-green-1000 {
	fill: var(--color-green-1000);
}

.Color-icon-green-1100 path,
.Color-icon-green-1100 rect,
.Color-icon-green-1100 polygon,
.Color-icon-green-1100 circle,
.Color-icon-green-1100 {
	fill: var(--color-green-1100);
}

.Color-icon-coral-50 path,
.Color-icon-coral-50 rect,
.Color-icon-coral-50 polygon,
.Color-icon-coral-50 circle,
.Color-icon-coral-50 {
	fill: var(--color-coral-50);
}

.Color-icon-coral-100 path,
.Color-icon-coral-100 rect,
.Color-icon-coral-100 polygon,
.Color-icon-coral-100 circle,
.Color-icon-coral-100 {
	fill: var(--color-coral-100);
}

.Color-icon-coral-200 path,
.Color-icon-coral-200 rect,
.Color-icon-coral-200 polygon,
.Color-icon-coral-200 circle,
.Color-icon-coral-200 {
	fill: var(--color-coral-200);
}

.Color-icon-coral-300 path,
.Color-icon-coral-300 rect,
.Color-icon-coral-300 polygon,
.Color-icon-coral-300 circle,
.Color-icon-coral-300 {
	fill: var(--color-coral-300);
}

.Color-icon-coral-400 path,
.Color-icon-coral-400 rect,
.Color-icon-coral-400 polygon,
.Color-icon-coral-400 circle,
.Color-icon-coral-400 {
	fill: var(--color-coral-400);
}

.Color-icon-coral-500 path,
.Color-icon-coral-500 rect,
.Color-icon-coral-500 polygon,
.Color-icon-coral-500 circle,
.Color-icon-coral-500 {
	fill: var(--color-coral-500);
}

.Color-icon-coral-600 path,
.Color-icon-coral-600 rect,
.Color-icon-coral-600 polygon,
.Color-icon-coral-600 circle,
.Color-icon-coral-600 {
	fill: var(--color-coral-600);
}

.Color-icon-coral-700 path,
.Color-icon-coral-700 rect,
.Color-icon-coral-700 polygon,
.Color-icon-coral-700 circle,
.Color-icon-coral-700 {
	fill: var(--color-coral-700);
}

.Color-icon-coral-800 path,
.Color-icon-coral-800 rect,
.Color-icon-coral-800 polygon,
.Color-icon-coral-800 circle,
.Color-icon-coral-800 {
	fill: var(--color-coral-800);
}

.Color-icon-coral-900 path,
.Color-icon-coral-900 rect,
.Color-icon-coral-900 polygon,
.Color-icon-coral-900 circle,
.Color-icon-coral-900 {
	fill: var(--color-coral-900);
}

.Color-icon-coral-1000 path,
.Color-icon-coral-1000 rect,
.Color-icon-coral-1000 polygon,
.Color-icon-coral-1000 circle,
.Color-icon-coral-1000 {
	fill: var(--color-coral-1000);
}

.Color-icon-coral-1100 path,
.Color-icon-coral-1100 rect,
.Color-icon-coral-1100 polygon,
.Color-icon-coral-1100 circle,
.Color-icon-coral-1100 {
	fill: var(--color-coral-1100);
}

.Color-icon-blue-50 path,
.Color-icon-blue-50 rect,
.Color-icon-blue-50 polygon,
.Color-icon-blue-50 circle,
.Color-icon-blue-50 {
	fill: var(--color-blue-50);
}

.Color-icon-blue-100 path,
.Color-icon-blue-100 rect,
.Color-icon-blue-100 polygon,
.Color-icon-blue-100 circle,
.Color-icon-blue-100 {
	fill: var(--color-blue-100);
}

.Color-icon-blue-200 path,
.Color-icon-blue-200 rect,
.Color-icon-blue-200 polygon,
.Color-icon-blue-200 circle,
.Color-icon-blue-200 {
	fill: var(--color-blue-200);
}

.Color-icon-blue-300 path,
.Color-icon-blue-300 rect,
.Color-icon-blue-300 polygon,
.Color-icon-blue-300 circle,
.Color-icon-blue-300 {
	fill: var(--color-blue-300);
}

.Color-icon-blue-400 path,
.Color-icon-blue-400 rect,
.Color-icon-blue-400 polygon,
.Color-icon-blue-400 circle,
.Color-icon-blue-400 {
	fill: var(--color-blue-400);
}

.Color-icon-blue-500 path,
.Color-icon-blue-500 rect,
.Color-icon-blue-500 polygon,
.Color-icon-blue-500 circle,
.Color-icon-blue-500 {
	fill: var(--color-blue-500);
}

.Color-icon-blue-600 path,
.Color-icon-blue-600 rect,
.Color-icon-blue-600 polygon,
.Color-icon-blue-600 circle,
.Color-icon-blue-600 {
	fill: var(--color-blue-600);
}

.Color-icon-blue-700 path,
.Color-icon-blue-700 rect,
.Color-icon-blue-700 polygon,
.Color-icon-blue-700 circle,
.Color-icon-blue-700 {
	fill: var(--color-blue-700);
}

.Color-icon-blue-800 path,
.Color-icon-blue-800 rect,
.Color-icon-blue-800 polygon,
.Color-icon-blue-800 circle,
.Color-icon-blue-800 {
	fill: var(--color-blue-800);
}

.Color-icon-blue-900 path,
.Color-icon-blue-900 rect,
.Color-icon-blue-900 polygon,
.Color-icon-blue-900 circle,
.Color-icon-blue-900 {
	fill: var(--color-blue-900);
}

.Color-icon-blue-1000 path,
.Color-icon-blue-1000 rect,
.Color-icon-blue-1000 polygon,
.Color-icon-blue-1000 circle,
.Color-icon-blue-1000 {
	fill: var(--color-blue-1000);
}

.Color-icon-blue-1100 path,
.Color-icon-blue-1100 rect,
.Color-icon-blue-1100 polygon,
.Color-icon-blue-1100 circle,
.Color-icon-blue-1100 {
	fill: var(--color-blue-1100);
}
