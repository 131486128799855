/**
 * Do not edit directly
 * Generated on Mon, 03 Jun 2024 05:15:08 GMT
 */

:root {
  --box-shadow-overlay-left: [object Object];
  --box-shadow-overlay-right: [object Object];
  --box-shadow-overlay-top: [object Object];
  --box-shadow-overlay-bottom: [object Object];
  --box-shadow-overlay-interaction: [object Object];
  --box-shadow-button-interaction: [object Object];
  --box-shadow-container-interaction: [object Object];
  --box-shadow-container-default: [object Object];
  --box-shadow-none: [object Object];
  --text-decoration-underline: underline;
  --text-decoration-none: none;
  --text-case-capitalized: capitalize;
  --text-case-uppercase: uppercase;
  --text-case-none: none;
  --line-height-40: 2.5rem;
  --line-height-32: 2rem;
  --line-height-28: 1.75rem;
  --line-height-24: 1.5rem;
  --line-height-20: 1.25rem;
  --line-height-16: 1rem;
  --line-height-12: 0.75rem;
  --font-size-56: 3.5rem;
  --font-size-48: 3rem;
  --font-size-32: 2rem;
  --font-size-24: 1.5rem;
  --font-size-20: 1.25rem;
  --font-size-16: 1rem;
  --font-size-14: 0.875rem;
  --font-size-13: 0.8125rem;
  --font-size-12: 0.75rem;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-family-default: Rubik;
  --dimension-neg28: -1.75rem;
  --dimension-neg24: -1.5rem;
  --dimension-neg20: -1.25rem;
  --dimension-neg16: -1rem;
  --dimension-neg14: -0.875rem;
  --dimension-neg12: -0.75rem;
  --dimension-neg8: -0.5rem;
  --dimension-neg4: -0.25rem;
  --dimension-neg2: -0.125rem;
  --dimension-80: 5rem;
  --dimension-72: 4.5rem;
  --dimension-64: 4rem;
  --dimension-56: 3.5rem;
  --dimension-48: 3rem;
  --dimension-40: 2.5rem;
  --dimension-32: 2rem;
  --dimension-28: 1.75rem;
  --dimension-24: 1.5rem;
  --dimension-20: 1.25rem;
  --dimension-16: 1rem;
  --dimension-14: 0.875rem;
  --dimension-12: 0.75rem;
  --dimension-8: 0.5rem;
  --dimension-4: 0.25rem;
  --dimension-2: 0.125rem;
  --dimension-0: 0rem;
  --border-width-xlg: 0.1875rem;
  --border-width-lg: 0.125rem;
  --border-width-md: 0.0625rem;
  --border-width-sm: 0.03125rem;
  --border-radius-circle: 99rem;
  --border-radius-sm: 0.25rem;
  --border-radius-lg: 1.5rem;
  --opacity-050: 4%;
  --opacity-000: 0%;
  --opacity-1000: 100%;
  --opacity-900: 72%;
  --opacity-600: 48%;
  --opacity-400: 32%;
  --opacity-300: 24%;
  --opacity-150: 12%;
  --opacity-125: 10%;
  --opacity-100: 8%;
  --color-blue-1100: #061527;
  --color-blue-1000: #062441;
  --color-blue-900: #013964;
  --color-blue-800: #00508a;
  --color-blue-700: #006bb5;
  --color-blue-600: #0281d8;
  --color-blue-500: #0294f7;
  --color-blue-400: #66acff;
  --color-blue-300: #9ac2fe;
  --color-blue-200: #c7dbfc;
  --color-blue-100: #edf2fc;
  --color-blue-50: #f4f8ff;
  --color-coral-1100: #2f0700;
  --color-coral-1000: #49060b;
  --color-coral-900: #700116;
  --color-coral-800: #9b0122;
  --color-coral-700: #ca002f;
  --color-coral-600: #ed1a3d;
  --color-coral-500: #ff4451;
  --color-coral-400: #ff6666;
  --color-coral-300: #ffa7a0;
  --color-coral-200: #fccec9;
  --color-coral-100: #fcefed;
  --color-coral-50: #fff8f7;
  --color-green-1100: #0b1b11;
  --color-green-1000: #0a2817;
  --color-green-900: #003f22;
  --color-green-800: #005932;
  --color-green-700: #007643;
  --color-green-600: #018e53;
  --color-green-500: #01ae66;
  --color-green-400: #00c675;
  --color-green-300: #53e596;
  --color-green-200: #8af7b5;
  --color-green-100: #d5fbe1;
  --color-green-50: #f4fdf7;
  --color-purple-1100: #270036;
  --color-purple-1000: #3c0651;
  --color-purple-900: #5d017d;
  --color-purple-800: #8000ac;
  --color-purple-700: #a800e0;
  --color-purple-600: #c428ff;
  --color-purple-500: #d057ff;
  --color-purple-400: #dd80ff;
  --color-purple-300: #e9a8ff;
  --color-purple-200: #f1cdfc;
  --color-purple-100: #f9eefc;
  --color-purple-50: #f9f5fa;
  --color-orange-1100: #2b1300;
  --color-orange-1000: #401900;
  --color-orange-900: #5f2201;
  --color-orange-800: #872f00;
  --color-orange-700: #b03f00;
  --color-orange-600: #d34d00;
  --color-orange-500: #f25a00;
  --color-orange-400: #ff6f26;
  --color-orange-300: #ffa97f;
  --color-orange-200: #fccfb9;
  --color-orange-100: #fcefe9;
  --color-orange-50: #fdf8f6;
  --color-pink-1100: #290a1b;
  --color-pink-1000: #46062d;
  --color-pink-900: #6b0146;
  --color-pink-800: #940062;
  --color-pink-700: #c20181;
  --color-pink-600: #e8009b;
  --color-pink-500: #fc3da6;
  --color-pink-400: #ff66ba;
  --color-pink-300: #ffa4d2;
  --color-pink-200: #fccbe2;
  --color-pink-100: #fceef4;
  --color-pink-50: #fff7fb;
  --color-teal-1100: #001b1c;
  --color-teal-1000: #002a2c;
  --color-teal-900: #044043;
  --color-teal-800: #005b5f;
  --color-teal-700: #007579;
  --color-teal-600: #008f94;
  --color-teal-500: #00a3a9;
  --color-teal-400: #00c1c7;
  --color-teal-300: #28d8df;
  --color-teal-200: #7bebef;
  --color-teal-100: #cffafb;
  --color-teal-50: #f3feff;
  --color-info-1100: #10003d;
  --color-info-1000: #201368;
  --color-info-900: #332196;
  --color-info-800: #422bbc;
  --color-info-700: #573bdc;
  --color-info-600: #7957e9;
  --color-info-500: #9574f1;
  --color-info-400: #b194f7;
  --color-info-300: #cab4f7;
  --color-info-200: #dfd4f4;
  --color-info-100: #f1ecfd;
  --color-info-50: #f8f5ff;
  --color-warning-1100: #221600;
  --color-warning-1000: #332100;
  --color-warning-900: #5c3c00;
  --color-warning-800: #805300;
  --color-warning-700: #a36a00;
  --color-warning-600: #c17c00;
  --color-warning-500: #e19701;
  --color-warning-400: #f6a91c;
  --color-warning-300: #ffc156;
  --color-warning-200: #ffd988;
  --color-warning-100: #fff1d9;
  --color-warning-50: #fef7eb;
  --color-success-1100: #031b00;
  --color-success-1000: #042805;
  --color-success-900: #033a0f;
  --color-success-800: #005b19;
  --color-success-700: #028329;
  --color-success-600: #2a973c;
  --color-success-500: #43a34d;
  --color-success-400: #6dbd70;
  --color-success-300: #8acd8a;
  --color-success-200: #bee4b6;
  --color-success-100: #e5f7e0;
  --color-success-50: #f3f8f2;
  --color-critical-1100: #390a00;
  --color-critical-1000: #4e0300;
  --color-critical-900: #700600;
  --color-critical-800: #9c0000;
  --color-critical-700: #cc1c10;
  --color-critical-600: #e5362f;
  --color-critical-500: #ef5955;
  --color-critical-400: #f07e7c;
  --color-critical-300: #f6a6a4;
  --color-critical-200: #fed1d0;
  --color-critical-100: #fdeeed;
  --color-critical-50: #fdf5f4;
  --color-navy-1100: #0e1126;
  --color-navy-1000: #001a40;
  --color-navy-900: #00245b;
  --color-navy-800: #033176;
  --color-navy-700: #254b86;
  --color-navy-600: #426498;
  --color-navy-500: #6888b5;
  --color-navy-400: #89a3c7;
  --color-navy-300: #aec1dc;
  --color-navy-200: #cfd9e9;
  --color-navy-100: #f2f4f8;
  --color-navy-50: #f7f9fc;
  --color-indigo-1100: #0e153b;
  --color-indigo-1000: #001c5c;
  --color-indigo-900: #032185;
  --color-indigo-800: #2035cb;
  --color-indigo-700: #3751ff;
  --color-indigo-600: #5e6dff;
  --color-indigo-500: #6e7fff;
  --color-indigo-400: #8694ff;
  --color-indigo-300: #b2bcff;
  --color-indigo-200: #d1d7ff;
  --color-indigo-100: #ebedff;
  --color-indigo-50: #f4f5fe;
  --color-neutral-000: #ffffff;
  --color-neutral-1100: #0c0d10;
  --color-neutral-1000: #212328;
  --color-neutral-900: #373840;
  --color-neutral-800: #4a4c59;
  --color-neutral-700: #5c5e6e;
  --color-neutral-600: #717385;
  --color-neutral-500: #888b9d;
  --color-neutral-400: #9ea1b1;
  --color-neutral-300: #b5b7c3;
  --color-neutral-200: #cecfd9;
  --color-neutral-100: #ebecf2;
  --color-neutral-50: #f7f8fa;
  --color-gray-000: #ffffff;
  --color-gray-1100: #020000;
  --color-gray-1000: #1c1b1b;
  --color-gray-900: #363636;
  --color-gray-800: #4d4d4d;
  --color-gray-700: #676666;
  --color-gray-600: #7c7b7b;
  --color-gray-500: #8e8e8e;
  --color-gray-400: #a3a2a2;
  --color-gray-300: #bfbfbf;
  --color-gray-200: #d9d9d9;
  --color-gray-100: #f1f1f1;
  --color-gray-50: #f8f8f8;
  --spacing-neg100: var(--dimension-neg2);
  --spacing-000: var(--dimension-0);
  --spacing-1000: var(--dimension-48);
  --spacing-900: var(--dimension-32);
  --spacing-800: var(--dimension-24);
  --spacing-700: var(--dimension-20);
  --spacing-600: var(--dimension-16);
  --spacing-500: var(--dimension-14);
  --spacing-400: var(--dimension-12);
  --spacing-300: var(--dimension-8);
  --spacing-200: var(--dimension-4);
  --spacing-100: var(--dimension-2);
  --sizing-icon-xsm: var(--dimension-12);
  --sizing-icon-xlg: var(--dimension-32);
  --sizing-icon-lg: var(--dimension-24);
  --sizing-icon-md: var(--dimension-20);
  --sizing-icon-sm: var(--dimension-16);
  --sizing-action-xlg: var(--dimension-48);
  --sizing-action-lg: var(--dimension-40);
  --sizing-action-md: var(--dimension-32);
  --sizing-action-s: var(--dimension-28);
  --sizing-action-xs: var(--dimension-24);
  --color-overlay-secondary: rgba( var(--color-navy-1000) var(--opacity-600));
  --color-overlay-primary: rgba( var(--color-indigo-1000) var(--opacity-600));
  --color-overlay-neutral: rgba( var(--color-neutral-1000) var(--opacity-600));
  --color-border-focus: var(--color-indigo-500);
  --color-border-info-decorative: var(--color-info-100);
  --color-border-info-subtlest: var(--color-info-200);
  --color-border-info-subtle: var(--color-info-500);
  --color-border-info-medium: var(--color-info-700);
  --color-border-info-bold: var(--color-info-900);
  --color-border-warning-decorative: var(--color-warning-100);
  --color-border-warning-subtlest: var(--color-warning-200);
  --color-border-warning-subtle: var(--color-warning-400);
  --color-border-warning-medium: var(--color-warning-700);
  --color-border-warning-bold: var(--color-warning-900);
  --color-border-success-decorative: var(--color-success-100);
  --color-border-success-subtlest: var(--color-success-200);
  --color-border-success-subtle: var(--color-success-500);
  --color-border-success-medium: var(--color-success-700);
  --color-border-success-bold: var(--color-success-900);
  --color-border-critical-decorative: var(--color-indigo-100);
  --color-border-critical-subtlest: var(--color-critical-200);
  --color-border-critical-subtle: var(--color-critical-500);
  --color-border-critical-medium: var(--color-critical-700);
  --color-border-critical-bold: var(--color-critical-900);
  --color-border-primary-decorative: var(--color-indigo-100);
  --color-border-primary-subtlest: var(--color-indigo-200);
  --color-border-primary-subtle: var(--color-indigo-500);
  --color-border-primary-medium: var(--color-indigo-700);
  --color-border-primary-bold: var(--color-indigo-900);
  --color-border-secondary-inverse: var(--color-navy-100);
  --color-border-secondary-decorative: var(--color-navy-100);
  --color-border-secondary-subtlest: var(--color-navy-200);
  --color-border-secondary-subtle: var(--color-navy-500);
  --color-border-secondary-medium: var(--color-navy-700);
  --color-border-secondary-bold: var(--color-navy-900);
  --color-border-neutral-decorative: var(--color-neutral-100);
  --color-border-neutral-subtlest: var(--color-neutral-200);
  --color-border-neutral-subtle: var(--color-neutral-500);
  --color-border-neutral-medium: var(--color-neutral-700);
  --color-border-neutral-bold: var(--color-neutral-900);
  --color-background-blue-subtlest: var(--color-blue-50);
  --color-background-blue-subtle: var(--color-blue-100);
  --color-background-blue-on-subtle-selected: rgba( var(--color-blue-700) var(--opacity-125));
  --color-background-blue-on-subtle-pressed: rgba( var(--color-blue-700) var(--opacity-150));
  --color-background-blue-on-subtle-hover: rgba( var(--color-blue-700) var(--opacity-100));
  --color-background-coral-subtlest: var(--color-coral-50);
  --color-background-coral-subtle: var(--color-coral-100);
  --color-background-coral-on-subtle-selected: rgba( var(--color-coral-700) var(--opacity-125));
  --color-background-coral-on-subtle-pressed: rgba( var(--color-coral-700) var(--opacity-150));
  --color-background-coral-on-subtle-hover: rgba( var(--color-coral-700) var(--opacity-100));
  --color-background-green-subtlest: var(--color-green-50);
  --color-background-green-subtle: var(--color-green-100);
  --color-background-green-on-subtle-selected: rgba( var(--color-green-700) var(--opacity-125));
  --color-background-green-on-subtle-pressed: rgba( var(--color-green-700) var(--opacity-150));
  --color-background-green-on-subtle-hover: rgba( var(--color-green-700) var(--opacity-100));
  --color-background-purple-subtlest: var(--color-purple-50);
  --color-background-purple-subtle: var(--color-purple-100);
  --color-background-purple-on-subtle-selected: rgba( var(--color-purple-700) var(--opacity-125));
  --color-background-purple-on-subtle-pressed: rgba( var(--color-purple-700) var(--opacity-150));
  --color-background-purple-on-subtle-hover: rgba( var(--color-purple-700) var(--opacity-100));
  --color-background-orange-subtlest: var(--color-orange-50);
  --color-background-orange-subtle: var(--color-orange-100);
  --color-background-orange-on-subtle-selected: rgba( var(--color-orange-700) var(--opacity-125));
  --color-background-orange-on-subtle-pressed: rgba( var(--color-orange-700) var(--opacity-150));
  --color-background-orange-on-subtle-hover: rgba( var(--color-orange-700) var(--opacity-100));
  --color-background-pink-subtlest: var(--color-pink-50);
  --color-background-pink-subtle: var(--color-pink-100);
  --color-background-pink-on-subtle-selected: rgba( var(--color-pink-700) var(--opacity-125));
  --color-background-pink-on-subtle-pressed: rgba( var(--color-pink-700) var(--opacity-150));
  --color-background-pink-on-subtle-hover: rgba( var(--color-pink-700) var(--opacity-100));
  --color-background-teal-subtlest: var(--color-teal-50);
  --color-background-teal-subtle: var(--color-teal-100);
  --color-background-teal-on-subtle-selected: rgba( var(--color-teal-700) var(--opacity-125));
  --color-background-teal-on-subtle-pressed: rgba( var(--color-teal-700) var(--opacity-150));
  --color-background-teal-on-subtle-hover: rgba( var(--color-teal-700) var(--opacity-100));
  --color-background-desaturated-subtlest: var(--color-gray-000);
  --color-background-desaturated-subtle: var(--color-gray-100);
  --color-background-desaturated-medium: var(--color-gray-200);
  --color-background-desaturated-bold: var(--color-gray-700);
  --color-background-desaturated-inverse: var(--color-gray-1000);
  --color-background-info-on-subtle-selected: rgba( var(--color-info-700) var(--opacity-125));
  --color-background-info-on-subtle-pressed: rgba( var(--color-info-700) var(--opacity-150));
  --color-background-info-on-subtle-hover: rgba( var(--color-info-700) var(--opacity-100));
  --color-background-info-subtlest: var(--color-info-50);
  --color-background-info-subtle: var(--color-info-100);
  --color-background-info-medium: var(--color-info-200);
  --color-background-info-bold: var(--color-info-700);
  --color-background-warning-on-subtle-selected: rgba( var(--color-warning-400) var(--opacity-125));
  --color-background-warning-on-subtle-pressed: rgba( var(--color-warning-400) var(--opacity-150));
  --color-background-warning-on-subtle-hover: rgba( var(--color-warning-400) var(--opacity-100));
  --color-background-warning-subtlest: var(--color-warning-50);
  --color-background-warning-subtle: var(--color-warning-100);
  --color-background-warning-medium: var(--color-warning-200);
  --color-background-warning-bold: var(--color-warning-400);
  --color-background-success-on-subtle-selected: rgba( var(--color-success-700) var(--opacity-125));
  --color-background-success-on-subtle-pressed: rgba( var(--color-success-700) var(--opacity-150));
  --color-background-success-on-subtle-hover: rgba( var(--color-success-700) var(--opacity-100));
  --color-background-success-subtlest: var(--color-success-50);
  --color-background-success-subtle: var(--color-success-100);
  --color-background-success-medium: var(--color-success-200);
  --color-background-success-bold: var(--color-success-700);
  --color-background-critical-on-subtle-selected: rgba( var(--color-critical-700) var(--opacity-125));
  --color-background-critical-on-subtle-pressed: rgba( var(--color-critical-700) var(--opacity-150));
  --color-background-critical-on-subtle-hover: rgba( var(--color-critical-700) var(--opacity-100));
  --color-background-critical-subtlest: var(--color-critical-50);
  --color-background-critical-subtle: var(--color-critical-100);
  --color-background-critical-medium: var(--color-critical-200);
  --color-background-critical-bold: var(--color-critical-700);
  --color-background-secondary-on-subtle-inverse-selected: rgba( var(--color-navy-400) var(--opacity-125));
  --color-background-secondary-on-subtle-inverse-pressed: rgba( var(--color-navy-400) var(--opacity-150));
  --color-background-secondary-on-subtle-inverse-hover: rgba( var(--color-navy-400) var(--opacity-100));
  --color-background-secondary-on-subtle-selected: rgba( var(--color-navy-700) var(--opacity-125));
  --color-background-secondary-on-subtle-pressed: rgba( var(--color-navy-700) var(--opacity-150));
  --color-background-secondary-on-subtle-hover: rgba( var(--color-navy-700) var(--opacity-100));
  --color-background-secondary-subtlest: var(--color-navy-50);
  --color-background-secondary-subtle: var(--color-navy-100);
  --color-background-secondary-medium: var(--color-navy-200);
  --color-background-secondary-bold: var(--color-navy-700);
  --color-background-secondary-inverse: var(--color-navy-1000);
  --color-background-primary-on-subtle-selected: rgba( var(--color-indigo-700) var(--opacity-125));
  --color-background-primary-on-subtle-pressed: rgba( var(--color-indigo-700) var(--opacity-150));
  --color-background-primary-on-subtle-hover: rgba( var(--color-indigo-700) var(--opacity-100));
  --color-background-primary-subtlest: var(--color-indigo-50);
  --color-background-primary-subtle: var(--color-indigo-100);
  --color-background-primary-medium: var(--color-indigo-200);
  --color-background-primary-bold: var(--color-indigo-700);
  --color-background-neutral-on-subtle-inverse-selected: rgba( var(--color-neutral-400) var(--opacity-125));
  --color-background-neutral-on-subtle-inverse-pressed: rgba( var(--color-neutral-400) var(--opacity-150));
  --color-background-neutral-on-subtle-inverse-hover: rgba( var(--color-neutral-400) var(--opacity-100));
  --color-background-neutral-disabled-medium: var(--color-neutral-200);
  --color-background-neutral-disabled-subtle: var(--color-neutral-100);
  --color-background-neutral-on-subtle-selected: rgba( var(--color-neutral-700) var(--opacity-125));
  --color-background-neutral-on-subtle-pressed: rgba( var(--color-neutral-700) var(--opacity-150));
  --color-background-neutral-on-subtle-hover: rgba( var(--color-neutral-700) var(--opacity-100));
  --color-background-neutral-on-bold-selected: rgba( var(--color-neutral-1000) var(--opacity-300));
  --color-background-neutral-on-bold-pressed: rgba( var(--color-neutral-1000) var(--opacity-400));
  --color-background-neutral-on-bold-hover: rgba( var(--color-neutral-000) var(--opacity-300));
  --color-background-neutral-subtlest: var(--color-neutral-000);
  --color-background-neutral-subtle: var(--color-neutral-100);
  --color-background-neutral-medium: var(--color-neutral-200);
  --color-background-neutral-bold: var(--color-neutral-700);
  --color-background-neutral-inverse: var(--color-neutral-1000);
  --color-page-subtle-desaturated: var(--color-gray-50);
  --color-page-subtle: var(--color-navy-100);
  --color-page-bold: var(--color-neutral-000);
  --color-foreground-blue-subtle: var(--color-blue-500);
  --color-foreground-coral-subtle: var(--color-coral-500);
  --color-foreground-green-subtle: var(--color-green-500);
  --color-foreground-purple-subtle: var(--color-purple-500);
  --color-foreground-orange-subtle: var(--color-orange-500);
  --color-foreground-pink-subtle: var(--color-pink-500);
  --color-foreground-teal-subtle: var(--color-teal-500);
  --color-foreground-info-subtle: var(--color-info-500);
  --color-foreground-info-medium: var(--color-info-700);
  --color-foreground-info-bold: var(--color-info-900);
  --color-foreground-warning-subtle: var(--color-warning-500);
  --color-foreground-warning-medium: var(--color-warning-700);
  --color-foreground-warning-bold: var(--color-warning-900);
  --color-foreground-success-subtle: var(--color-success-500);
  --color-foreground-success-medium: var(--color-success-700);
  --color-foreground-success-bold: var(--color-success-900);
  --color-foreground-critical-subtle: var(--color-critical-500);
  --color-foreground-critical-medium: var(--color-critical-700);
  --color-foreground-critical-bold: var(--color-critical-900);
  --color-foreground-secondary-subtle: var(--color-navy-500);
  --color-foreground-secondary-medium: var(--color-navy-700);
  --color-foreground-secondary-bold: var(--color-navy-900);
  --color-foreground-primary-subtle: var(--color-indigo-500);
  --color-foreground-primary-medium: var(--color-indigo-700);
  --color-foreground-primary-bold: var(--color-indigo-900);
  --color-foreground-neutral-inverse: var(--color-neutral-000);
  --color-foreground-neutral-disabled: var(--color-neutral-400);
  --color-foreground-neutral-subtle: var(--color-neutral-600);
  --color-foreground-neutral-medium: var(--color-neutral-800);
  --color-foreground-neutral-bold: var(--color-neutral-1000);
}
